// "Non metterti a sconvolgere il repo con nomi strani di classe arbitrari"
// --@Nemesis-FT, 2022-12-23, 10:47

import Style from "./WeirdFlex.module.css"


export function WeirdFlex/*ButOk*/(props) {
    return (
        <div className={Style.WeirdFlex} {...props}/>
    )
}
